.footer {
  /* @mixin light {
    background-color: white;
  } */
  @mixin dark {
    background-color: var(--mantine-color-dark-6);
  }
}

.main {
  @mixin light {
    background-color: var(--mantine-color-gray-0);
  }
  @mixin dark {
    background-color: var(--mantine-color-dark-7);
  }
}
