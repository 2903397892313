.root {
  padding: 0.25rem 0.5rem;
  color: var(--mantine-color-text);
  border-radius: var(--mantine-radius-md);
  &:hover {
    background-color: var(--mantine-color-default-hover);
  }
}

.icon {
  transition: transform 150ms ease;
  transform: rotate(0deg);

  [data-expanded] & {
    transform: rotate(180deg);
  }
}
