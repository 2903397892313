/* @mixin light {
    background-color: white;
  } */
  [data-mantine-color-scheme='dark'] .layout_footer__lldqG {
    background-color: var(--mantine-color-dark-6)
}

[data-mantine-color-scheme='light'] .layout_main__WKREA {
    background-color: var(--mantine-color-gray-0)
}

[data-mantine-color-scheme='dark'] .layout_main__WKREA {
    background-color: var(--mantine-color-dark-7)
}

.Footer_footer__rZB2Q {
  /* margin-top: rem(120px); */
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  background-color: var(--mantine-color-white);
  /* border-top: rem(1px) solid var(--app-shell-border-color); */
}
  [data-mantine-color-scheme='dark'] .Footer_footer__rZB2Q {
  background-color: var(--mantine-color-dark-6);
}

.Footer_logo__Rbx6w {
  max-width: calc(12.5rem * var(--mantine-scale));
}

@media (max-width: 48em) {

.Footer_logo__Rbx6w {
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center
}
  }

.Footer_description__eTXsn {
  margin-top: calc(0.3125rem * var(--mantine-scale));
}

@media (max-width: 48em) {

.Footer_description__eTXsn {
    margin-top: var(--mantine-spacing-xs);
    text-align: center
}
  }

.Footer_inner__0_3gK {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 48em) {

.Footer_inner__0_3gK {
    flex-direction: column;
    align-items: center
}
  }

.Footer_groups__u5xMm {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 48em) {

.Footer_groups__u5xMm {
    display: none
}
  }

.Footer_wrapper__R87oh {
  width: calc(10rem * var(--mantine-scale));
}

.Footer_link__16ENv {
  display: block;
  color: var(--mantine-color-gray-6);
}

[data-mantine-color-scheme='dark'] .Footer_link__16ENv {
  color: var(--mantine-color-dark-1);
}

.Footer_link__16ENv {
  font-size: var(--mantine-font-size-sm);
  padding-top: calc(0.1875rem * var(--mantine-scale));
  padding-bottom: calc(0.1875rem * var(--mantine-scale));
}

.Footer_link__16ENv:hover {
    text-decoration: underline;
  }

.Footer_title__3tfHT {
  font-size: var(--mantine-font-size-lg);
  font-weight: 700;
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  margin-bottom: calc(var(--mantine-spacing-xs) / 2);
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] .Footer_title__3tfHT {
  color: var(--mantine-color-white);
}

.Footer_afterFooter__L0dIU {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* margin-top: var(--mantine-spacing-xl); */

/* padding-top: var(--mantine-spacing-xl); */

/* padding-bottom: var(--mantine-spacing-xl); */

/* border-top: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4)); */

@media (max-width: 48em) {

.Footer_afterFooter__L0dIU {
    flex-direction: column
}
  }

@media (max-width: 48em) {

.Footer_social__qlZ7e {
    margin-top: var(--mantine-spacing-xs)
}
  }

.AccountMenu_root__bEyTt {
  padding: 0.25rem 0.5rem;
  color: var(--mantine-color-text);
  border-radius: var(--mantine-radius-md);
}
  .AccountMenu_root__bEyTt:hover {
    background-color: var(--mantine-color-default-hover);
  }

.AccountMenu_icon__Ixn7d {
  transition: transform 150ms ease;
  transform: rotate(0deg);
}

[data-expanded] .AccountMenu_icon__Ixn7d {
    transform: rotate(180deg);
  }

